module.exports = {
  siteTitle: 'Deep Habits', // <title>
  manifestName: 'landing',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/logo.png',
  pathPrefix: `/gatsby-starter-newage/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  socialLinks: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },
    // {
    //   icon: 'fa-linkedin-in',
    //   name: 'Linkedin',
    //   url: 'https://linkedin.com/in/theanubhav/',
    // },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/deephabits.app',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/DeepHabitsApp',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/DeepHabits',
    },
  ],
};
