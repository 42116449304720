import React, { useState } from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
// import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';

import demo1 from '../assets/images/deep-habits-main-iPhone-11-Pro-2021-04-12.png';
import demo2 from '../assets/images/deep-habits-friendship-iPhone-11-Pro-2021-04-12.png';
// import gplay from '../assets/images/google-play-badge.svg';
// import astore from '../assets/images/app-store-badge.svg';

const IndexPage = () => {
  const [betaSubmitting, setBetaSubmitting] = useState(false);
  console.log('betaSubmitting:', betaSubmitting);
  return (
    <Layout>
      <Header />

      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-7 my-auto">
              <div className="header-content mx-auto">
                <h1 className="mb-5">Deep Habits</h1>
                <h3 className="mb-4">
                  Better Habits, with a little help from your friends!
                </h3>
                <ul>
                  <li>Set an intention for new habits</li>
                  <li>Visualize your activity and progress</li>
                  <li>Journal challenges and successes</li>
                  <li>
                    Share with friends for accountability and encouragement
                  </li>
                </ul>
                <section id="signup-form">
                  <h4 className="mb-3">Deep Habits is in βeta on iOS</h4>
                  <h6>No invite yet? Sign up for early access</h6>
                  <form
                    onSubmit={async (event) => {
                      const elements = event.target.elements;
                      setBetaSubmitting(true);
                      const result = await betaSignUp(
                        elements.emailBeta.value,
                        elements.androidOnly.checked
                      );
                      setBetaSubmitting(false);
                      console.log('betaSignUp.result:', result);
                    }}
                  >
                    <div className="form-group mb-2">
                      <input
                        className="form-control"
                        id="emailBeta"
                        type="email"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="form-group mb-2 android-check">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="androidOnly"
                          name="androidOnly"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="androidOnly"
                        >
                          Notify me when the Android version is available
                          <span
                            className="ml-1"
                            style={{
                              fontWeight: '900',
                              fontVariant: 'small-caps',
                            }}
                          >
                            (coming soon)
                          </span>
                        </label>
                      </div>
                    </div>
                    <button className="btn btn-xl" disabled={betaSubmitting}>
                      {betaSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {/* <span className="visually-hidden">Submitting...</span> */}
                      <span>
                        {betaSubmitting
                          ? 'Submitting...'
                          : 'Sign Up For Early Access'}
                      </span>
                    </button>
                  </form>
                </section>
              </div>
            </div>
            <div className="col-lg-5 my-auto">
              <div className="device-container">
                <div
                  className="device"
                  data-device="iPhoneX"
                  data-orientation="portrait"
                  data-color="black"
                >
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="features" id="features">
        <div className="container">
          <div className="section-heading text-center">
            <h2>Improve Your Habits, Improve Yourself</h2>
            {/* <ul className="text-muted" style={{ textAlign: 'left' }}>
            <li>Decide what you want to do</li>
            <li>Hold yourself accountable</li>
            <li>Support and connect with your friends</li>
          </ul> */}
            <hr />
          </div>
          <div className="row">
            <div className="col-lg-4 my-auto">
              <div className="device-container">
                {/* <div className="device-wrapper"> */}
                <div
                  className="device"
                  data-device="iPhoneX"
                  data-orientation="portrait"
                  data-color="black"
                >
                  <div className="screen">
                    <img src={demo2} className="img-fluid" alt="" />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className="col-lg-8 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="feature-item">
                      <i className="icon-screen-smartphone text-primary"></i>
                      <h3>Set An Intention</h3>
                      <p className="text-muted">
                        By adding and tracking your habits you make your
                        intentions more concrete.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="feature-item">
                      <i className="icon-camera text-primary"></i>
                      <h3>Visualize Progress</h3>
                      <p className="text-muted">
                        See clearly what you have (or haven't) been doing, and
                        how you're progressing over time.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="feature-item">
                      <i className="icon-present text-primary"></i>
                      <h3>Journal To Learn</h3>
                      <p className="text-muted">
                        Even if you never read it again, journaling helps you
                        think about how and why you're trying to improve habits.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="feature-item">
                      <i className="icon-lock-open text-primary"></i>
                      <h3>Get Help From Friends</h3>
                      <p className="text-muted">
                        Sharing will help you follow-through. You can provide
                        accountability and support for each other, and connect
                        over something real and important.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="cta">
      <div className="cta-content">
        <div className="container">
          <h2>Get Started Now</h2>
          <Scroll type="id" element="signup-form">
            <a href="#contact" className="btn btn-outline btn-xl">
              Sign Up!
            </a>
          </Scroll>
        </div>
      </div>
      <div className="overlay"></div>
    </section> */}

      <section className="contact bg-primary" id="contact">
        <div className="container">
          <h2>
            We&nbsp;
            <i className="fas fa-heart" style={{ color: '#c00' }}></i>
            &nbsp;new friends!
          </h2>
          <Social />
        </div>
      </section>

      <Footer />
    </Layout>
  );
};
const funcURLPrefix = `https://us-central1-deep-habits-app.cloudfunctions.net`;

async function betaSignUp(email, androidOnly) {
  let platforms = [];
  if (androidOnly) platforms.push('android');
  try {
    const response = await fetch(`${funcURLPrefix}/betaSignUp`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({ data: { email, platforms } }),
    });
    const data = await response.json();
    const status = response.status;
    return { status, data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export default IndexPage;
